:root {
  --text-color: white;
  --font-family: Bellota;
  --background-color: #0e2536;
  --background-menu-color: #031a2b;
  --accent-color1: #8cb598;
  --accent-color2: #ca965d;
  --border-color: grey;
}

a,
a:link,
a:visited {
  color: var(--accent-color1);
  background-color: transparent;
  text-decoration: none;
}

a:hover {
  color: var(--accent-color2) !important;
  background-color: transparent;
  text-decoration: underline;
}

a:active {
  color: var(--accent-color2);
  background-color: transparent;
  text-decoration: underline;
}

.ant-menu-title-content > a {
  text-decoration: none;
}

.title {
  font-size: 48px;
  align-self: center;
  font-weight: bold;
}

body {
  background-color: var(--background-color) !important;
  width: calc(100%) !important;
}

.App {
  text-align: center;
  font-family: var(--font-family);
  color: var(--text-color);
  font-size: calc(10px + 1vw);
  display: flex;
}

.main-content {
  width: 100%;
}

.top-menu {
  background-color: var(--background-menu-color) !important;
}
.ant-menu-item-active > span > a {
  color: rgba(255, 255, 255, 1) !important;
}

.App-header {
  grid-template-areas:
    "pic"
    "name"
    "desc"
    "cta";
  display: grid;
  align-content: center;
  justify-content: center;
  gap: 20px;
  padding: 20px;
  min-height: 100vh;
}

.Author-name {
  color: var(--accent-color1);
  align-self: center;
  text-align: center;
  justify-self: center;
  font-size: 48px;
  grid-area: name;
}
.Author-desc {
  align-self: center;
  text-align: center;
  justify-self: center;
  font-size: 32px;
  grid-area: desc;
}
.Author-pic {
  align-self: center;
  text-align: center;
  justify-self: center;
  grid-area: pic;
  grid-row: span 1;
  margin: -10px -10px;
  min-width: 350px;
  min-height: 177px;
}
.Author-project-link {
  align-self: center;
  text-align: center;
  justify-self: center;
  padding: 10px 0px;
}
.Author-project-link > div {
  margin: 10px;
}
.Contact-desc {
  align-self: center;
  text-align: left;
  justify-self: center;
  width: 100%;
  font-size: 20px;
}

.Projects-grid {
  display: grid;
  padding: 20px;
  grid-template-columns: repeat(var(--repeat, auto-fit), minmax(200px, 1fr));
  gap: 20px;
}

.Project-cell {
  border: 1px dashed var(--border-color);
  padding: 1rem;
  display: grid;
  align-content: center;
  gap: 20px;
}

.Project-name {
  font-weight: bold;
}

.Contact {
  min-height: 100vh;
  display: grid;
  grid-template-rows: 150px 5fr;
  padding: 20px;
}

.antd-form-label {
  font-size: 20px;
}
.antd-form-tail {
  padding-top: 20px !important;
}

.hamburger {
  visibility: visible;
  margin: 20px;
  background-color: var(--background-color);
  height: auto;
  width: auto;
  position: fixed;
}

.menu {
  display: flex;
  justify-content: center;
  background-color: var(--background-color) !important;
  font-size: 24px;
  visibility: hidden;
  height: 0px;
  width: 0px;
}

.menu > div {
  margin: 25px 20px;
}

@media (min-width: 700px) {
  .App-header,
  .Projects,
  .Contact {
    min-height: calc(-46px + 100vh);
  }
  .top-menu {
    justify-content: center;
  }
  .App-header {
    grid-template-areas:
      "pic name"
      "pic desc"
      "pic cta";
  }
  .Author-name {
    text-align: left;
    justify-self: start;
  }
  .Author-pic {
    grid-row: span 3;
    justify-self: end;
  }
  .Author-project-link {
    justify-self: start;
    grid-area: cta;
    display: flex;
  }
  .Author-project-link > div {
    margin-left: 0px;
    margin-right: 20px;
  }
  .Author-desc {
    text-align: left;
    justify-self: start;
  }
  .menu {
    visibility: visible;
    height: auto;
    width: auto;
  }
  .hamburger {
    visibility: hidden;
    position: fixed;
  }
}
@media (min-width: 1000px) {
  .Contact-desc {
    width: 70%;
  }
}

.Projects {
  min-height: 100vh;
  display: grid;
  grid-template-rows: 150px 5fr;
}

.Projects-grid {
  --repeat: auto-fit;
}
@media (min-width: calc((200px + 40px) * 3)) {
  .Projects-grid {
    --repeat: 3;
  }
}

.Contact-form-message {
  text-align: center;
  /* font-size: calc(10px + 1vw); */
}
.Contact-form-spinner {
  width: 50px;
  height: 40px;
  margin: auto;
  transform: translate(-50%, -50%);
}
